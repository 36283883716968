<template>
  <header
    ref="header"
    class="header"
    :class="{
      visible: isVisibleRef && ui.showHeader,
      'header-open': isMenuOpen,
    }"
  >
    <div class="header-inner wrapper">
      <div class="header__country">{{ t('global.edenredCountry') }}</div>

      <!-- Header / Logo -->
      <RouterLink :to="`/${locale}/`" class="header__logo">
        <SvgSprite symbol="ui-logo" role="img" aria-label="disable" />
      </RouterLink>

      <!-- Header / Burger - mobile -->
      <div
        ref="burgerBtn"
        class="header__burger"
        :class="{ 'is-active': isMenuOpen }"
        @click="isMenuOpen = !isMenuOpen"
      >
        <span class="header__burger__span"></span>
        <span class="header__burger__span"></span>
      </div>

      <!-- Header / Main Nav -->
      <div class="header__menu wrapper">
        <NavMain
          :content="chrome.main_menu"
          class="header__menu__main"
        ></NavMain>

        <NavSecondary
          :content="chrome.secondary_menu"
          class="header__menu__secondary"
        ></NavSecondary>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import NavMain from '@/components/chrome/NavMain.vue'
import NavSecondary from '@/components/chrome/NavSecondary.vue'
import { useChromeStore } from '@/stores/chrome'
import { useUiStore } from '@/stores/ui'
import { useResize } from '@/utils/aware'

const chrome = useChromeStore()
gsap.registerPlugin(Observer)

const ui = useUiStore()

const burgerBtn = ref<HTMLElement>()
const header = ref<HTMLElement>()
const isVisibleRef = ref(true)

const isMobile = ref(false)
const isMenuOpen = ref(false)
const tempHeight = ref()
const { locale, t } = useI18n({ useScope: 'global' })
const router = useRouter()

// Close menu between navigation and on active link click
router.afterEach(() => {
  isMenuOpen.value = false

  ui.showHeader = true
})

const onWindowResize = () => {
  isMobile.value = window.innerWidth < 1024

  if (header.value) {
    if (tempHeight.value === window.innerWidth) {
      return
    }

    document.documentElement.style.setProperty(
      '--header-height',
      `${header.value.offsetHeight}px`
    )

    tempHeight.value = header.value.offsetHeight
  }
}

let observer: Observer

onMounted(() => {
  useResize(onWindowResize)

  if (!isMobile.value && burgerBtn.value) {
    burgerBtn.value.classList.remove('is-active')
    document.body.classList.remove('prevent-scroll')
  }

  observer = Observer.create({
    target: window,
    type: 'scroll',
    onUp: o => {
      if (o.scrollY() >= 0) {
        isVisibleRef.value = true
      }
    },
    onDown: o => {
      if (o.scrollY() > 300) {
        isVisibleRef.value = false
      }
    },
  })
})

watch(isMenuOpen, () => {
  if (isMenuOpen.value) {
    ui.toggleScroll(false)
  } else {
    ui.toggleScroll(true)
  }
})

onBeforeUnmount(() => {
  observer?.kill()
  isMenuOpen.value = false
})
</script>

<style lang="scss" scoped>
$bp: l;

.header {
  position: fixed;
  z-index: $header-z-index + 2;
  width: 100%;
  height: auto;
  transform: translateY(-100%);
  background-color: $c-white;
  box-shadow: 0 -10px 25px 5px rgba($c-black, 0.1);
  transition: transform 0.2s ease-in-out;

  &.visible {
    transform: translateY(0);
  }
}

.header-inner {
  display: flex;
  align-items: center;

  @include mq($until: $bp) {
    padding: 1.5rem 2.5rem;
  }

  @include mq($bp) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 13.1rem 1fr;
    padding-top: $spacing * 0.5;
    padding-bottom: $spacing * 0.75;
  }
}

.header__country {
  @extend %tiny;

  margin: 0 1.5rem;

  @include mq($bp) {
    grid-row: 1/2;
    grid-column: 1/2;
    margin: 0;
  }
}

.header__logo {
  position: relative;
  flex-shrink: 0;
  order: -1;

  svg {
    width: 6.5rem;
    height: 4rem;
    vertical-align: middle;
    fill: $c-red;
  }

  @include mq($bp) {
    grid-row: 2/3;
    grid-column: 1/2;

    svg {
      width: 13.1rem;
      height: 8rem;
    }
  }
}

.header__burger {
  display: inline-block;
  cursor: pointer;
  margin-left: auto;

  span {
    pointer-events: none;
  }

  @include mq($bp) {
    display: none;
  }
}

.header__burger__span {
  display: block;
  width: 3.6rem;
  height: 0.2rem;
  background: $c-black;
  border-radius: 2rem;
  transition: 0.3s;

  & + & {
    margin-top: 0.6rem;
  }

  .header__burger.is-active &:nth-child(2) {
    transform: rotate(45deg);
  }

  .header__burger.is-active &:nth-child(1) {
    position: relative;
    top: 0.75rem;
    transform: rotate(-45deg);
  }

  .header__burger.is-active &:nth-child(3) {
    position: relative;
    top: -0.8rem;
    transform: rotate(-45deg);
    width: 100%;
    margin-left: 0;
  }
}

.header__menu {
  position: absolute;
  top: 7rem;
  right: 0;
  left: 0;
  display: none;
  height: calc(100vh - 10rem);
  padding: 2.2rem 0;
  background: $c-white;

  @include mq($until: $bp) {
    .header__burger.is-active ~ & {
      display: block;
      overflow: scroll;
      padding-bottom: 8rem;
    }
  }

  @include mq($bp) {
    position: static;
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 1/3;
    grid-column: 2/3;
    height: auto;
    padding: 0;
  }
}

.header__menu__main {
  @include mq($bp) {
    grid-row: 2/3;
  }
}

.header__menu__secondary {
  @include mq($bp) {
    grid-row: 1/2;
    padding-top: 0;
  }
}
</style>
