<template>
  <ul v-if="content" ref="list" class="main-nav-list">
    <template v-for="(item, index) of content" :key="index">
      <li
        ref="menuItem"
        class="main-nav-item"
        :class="{ active: temp === index, btn: item.classes === 'btn' }"
        @click="onMainNavLinkClick(index)"
      >
        <Action
          v-if="item.classes === 'btn'"
          :content="{
            title: item.label,
            url: item.url,
            modifiers: ['btn'],
          }"
        />

        <component
          :is="isMobile && item.children ? 'button' : 'RouterLink'"
          v-else
          :to="isMobile && item.children ? null : item.url"
          class="main-nav-link"
        >
          {{ item.label }}
          <div
            class="main-nav-link__decorator"
            :class="item.isCta ? 'hidden' : ''"
          ></div>

          <SvgSprite
            v-if="isMobile && item.children"
            symbol="ui-arrow"
            size="24"
            role="img"
            aria-label="disable"
          />
        </component>

        <div v-if="item.children" ref="submenu" class="submenu">
          <ul class="submenu__list">
            <li
              v-for="(child, i) of item.children"
              :key="i"
              class="submenu__item"
              :class="child.classes"
            >
              <Action
                v-if="child.classes === 'all'"
                :content="{
                  title: child.label,
                  url: child.url,
                  modifiers: ['btn', 'black'],
                }"
              />
              <RouterLink
                v-else
                :to="child.url"
                class="submenu__item__link"
                :class="child.classes"
              >
                <img
                  v-if="child.icon"
                  class="submenu__item__link-icon"
                  :src="child.icon"
                  alt=""
                />

                {{ child.label }}
                <div class="arrow-right">
                  <span></span>
                  <span></span>
                </div>
              </RouterLink>
            </li>
          </ul>
        </div>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { onMounted, ref } from 'vue'

import { useResize } from '@/utils/aware'

import type { PropType } from 'vue'
import type { Picture } from '@/types'

interface MainMenu {
  label: string
  text?: string
  url: string
  isCta?: boolean
  classes?: string
  caption?: string
  children?: {
    label: string
    icon?: string
    url: string
    classes?: string
  }[]
  last_news?: {
    title: string
    url: string
    picture: Picture
  }
  sector: string
}

defineProps({
  content: {
    type: Object as PropType<MainMenu[]>,
    required: true,
    default: () => ({}),
  },
})

const isMobile = ref(false)
const list = ref()
const submenu = ref()
const menuItem = ref()

const onWindowResize = () => {
  isMobile.value = window.innerWidth < 1024

  if (submenu.value) {
    if (isMobile.value) {
      submenu.value.forEach((element: HTMLElement) => {
        resetSubmenuHeight(element)
      })
    } else {
      submenu.value.forEach((element: HTMLElement) => {
        gsap.set(element, { clearProps: 'height' })
      })
    }
  }
}

onMounted(() => {
  useResize(onWindowResize)
})

const temp = ref()

const onMainNavLinkClick = (index: number) => {
  if (!isMobile.value || !menuItem.value) {
    return
  }

  const itemSubmenu = menuItem.value[index].querySelector('.submenu')

  if (index === temp.value) {
    resetSubmenuHeight(itemSubmenu)

    return
  }

  submenu.value.forEach(resetSubmenuHeight)

  gsap.fromTo(
    itemSubmenu,
    { height: 0 },
    { height: 'auto', ease: 'power3.inOut' }
  )

  temp.value = index
}

const resetSubmenuHeight = (element: HTMLElement) => {
  gsap.to(element || {}, {
    ease: 'power3.inOut',
    height: 0,
    onComplete: () => {
      gsap.set(element, { clearProps: 'height' })
    },
  })

  temp.value = 99
}
</script>

<style lang="scss" scoped>
$bp: l;

.main-nav-list {
  list-style: none;
  margin: 0;
  padding: 0;

  @include mq($until: $bp) {
    margin-top: 4rem;
  }

  @include mq($bp) {
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  @include mq(xl) {
    text-align: left;
  }
}

.main-nav-link {
  @extend %button-nostyle;

  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: $c-black;
  font-family: $ff-alt;
  font-size: 2.8rem;
  line-height: 3.6rem;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 0.3rem;
    background: $c-red;
    border-radius: 0.3rem;
    transform: scaleX(0);
    transition: transform 0.3s ease-out;
    transform-origin: left center;

    @include mq($bp) {
      bottom: 0;
    }
  }

  .page-active & {
    color: $c-black;

    :deep(.arrow-right span) {
      background: $c-black;
    }

    &::after {
      background: $c-black;
    }
  }

  &:focus,
  .main-nav-item:hover & {
    &::after {
      @include mq($bp) {
        transform: scaleX(1);
      }
    }
  }

  .arrow-right {
    @include mq($bp) {
      display: none;
    }
  }

  :deep(svg) {
    stroke: $c-black;
    fill: transparent;

    .main-nav-item.active & {
      transform: rotate(180deg);
    }
  }

  @include mq($until: $bp) {
    width: 100%;
    padding: 1.5rem 2.5rem;
    border-bottom: 1px solid #6d778733;
  }

  @include mq($bp) {
    display: block;
    padding: 0.8rem 0;
    color: $c-black;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    background-position: bottom;
    transition: 0.3s;
  }
}

.main-nav-item {
  display: block;
  padding: 0;

  &:last-child {
    padding-right: 0;

    @include mq($bp) {
      @include m-space;

      margin-top: 0;
    }
  }

  .btn {
    margin-left: 0;
  }

  &.active {
    .main-nav-link {
      border-color: transparent;

      @include mq($until: $bp) {
        background-color: rgba(15, 139, 255, 5%);
      }
    }
  }

  @include mq($until: $bp) {
    &.btn {
      .header-open & {
        position: fixed;
        z-index: $header-z-index;
        top: calc(100 * var(--vh));
        width: 100%;
        margin-top: 0;
        padding: 1rem 2.5rem;
        background-color: $c-white;
        border-top: 1px solid rgba(109, 119, 135, 10%);
        transform: translateY(-100%);
      }
    }
  }

  @include mq($bp) {
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    padding: 1rem 2rem;

    &:not(:first-child) {
      margin-top: 0;
    }
  }
}

.main-nav-link-icon-mobile {
  display: none;

  @include mq($until: 1024px) {
    position: absolute;
    top: 0.8rem;
    right: 2.5rem;
    display: block;
  }
}

.submenu {
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;

  .active & {
    border-bottom: 1px solid #6d778733;
  }

  @include mq($until: l) {
    height: 0;
  }

  @include mq($bp) {
    position: absolute;
    top: initial;
    bottom: 0;
    left: 50%;
    overflow: visible;
    width: auto;
    height: auto;
    padding: 0;
    font-weight: 400;
    text-align: left;
    background: $c-white;
    border-radius: 2rem;
    box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 25%);
    opacity: 0;
    transform: translate(-50%, 100%);
    pointer-events: none;

    &:hover,
    &:has(*:focus-visible),
    .main-nav-link:hover + &,
    .main-nav-link:focus + & {
      opacity: 1;
      pointer-events: all;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 3rem);
    }
  }
}

.submenu__back {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.6rem;
  margin-bottom: 2rem;
  margin-left: calc(100vw / 20);
  color: rgba($c-black, 0.5);
  font-family: $ff-default;
  font-size: 1.8rem;
  background: 0;
  border: 0;
  cursor: pointer;

  @include mq($bp) {
    display: none;
  }
}

.submenu__back__icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  fill: rgba($c-black, 0.5);
}

.submenu__list {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;
  padding: 3rem 5rem 3rem 3rem;
  gap: 1.4rem;

  @include mq($until: $bp) {
    padding: 2.4rem 5rem;
  }

  @include mq($bp) {
    gap: 1rem;
  }
}

.submenu__item {
  &.all {
    margin-top: 2rem;
  }

  @include mq($bp) {
    &.all {
      margin-top: 2.5rem;
    }
  }
}

.submenu__item__link {
  @extend %fw-medium;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  // padding: 1rem 2.5rem;
  color: $c-black;
  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 2.2rem;

  @include mq($until: $bp) {
    .arrow-right {
      display: none;
    }
  }

  @include mq($bp) {
    position: relative;
    z-index: 1;

    // padding: 0 3rem;
    font-family: $ff-default;
    line-height: 3.6rem;
    white-space: nowrap;
    backface-visibility: hidden;

    .arrow-right {
      opacity: 0;
      transform: translateX(-1rem);
      transition: 0.2s;
    }

    &:hover,
    &:focus {
      .arrow-right {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &.all {
      top: 100%;
      left: 0;
      width: 100%;
      padding: 1rem 3rem;
      color: $c-white;
      background: $c-black;
      border-radius: 0 0 2rem 2rem;

      .arrow-right span {
        background: $c-white;
      }
    }
  }
}

.submenu__item__link-icon {
  width: 3.5rem;
  aspect-ratio: 1;
  margin-right: 1rem;
  fill: $c-black;
}
</style>
