import UAParser from 'ua-parser-js'

import type { Country } from '@/types'

/**
 * User Agent infos
 */
let ua: UAParser | null = null

if (import.meta.env && import.meta.env.SSR === false) {
  ua = new UAParser()
}

export { ua }

/**
 * URLs, segments, endpoints
 */
export const endpointDefault = 'pages'

/**
 * API URLs
 */
export const getApiUrl = (country: Country) =>
  `${import.meta.env.VITE_API_DOMAIN}/${country}${
    import.meta.env.VITE_API_PATH
  }` // TODO: EDENRED_COUNTRY
export const getThumborUrl = () =>
  `${import.meta.env.VITE_API_DOMAIN}${import.meta.env.VITE_THUMBOR_PATH}`

/**
 * GTM ID
 */
export const gtmId = {
  be: 'GTM-MP7KPFB',
  lu: 'GTM-PK5JP3C',
}
export const useGtm = true

/**
 * OneTrust
 */
export const onetrustId = {
  be: '2f91c491-3748-4aa4-9f2a-fe6cf2592645',
  lu: '8ec933bb-7241-40a1-8b84-307054d4aafa',
}
export const useOneTrust = true

/**
 * Google Site Verification
 */
// export const googleSite = 'xxx_yyyzzzzzzz'
export let googleSite: string

/**
 * Options
 */
export const emoji = '🔮'
export const darkModeOptions = {
  selector: 'html',
  attribute: 'color-scheme',
  valueDark: 'dark',
  valueLight: 'light',
}

// Grid
export const gridCols = 20

// Icons
export const iconViewbox = '0 0 24 24'
export const iconRegistered: Record<
  string,
  { symbol: string; viewbox?: string }
> = {
  arrow: { symbol: 'arrows-large-right' },
  download: { symbol: 'icons-download' },
  external: { symbol: 'ui-external' },
  filter: { symbol: 'ui-filter' },
} // as const
